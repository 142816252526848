import React from 'react';

import { styled } from 'styles';
import MemberCard from './MemberCard';
import dataTeamMembers from './dataTeamMembers';

const TeamSection = () => {
  return (
    <StyledTeamSection className="team-section">
      <div className="team-members-wrapper">
        <h3 className="team-members-wrapper__title">Our team</h3>
        <div className="team-members">
          {dataTeamMembers.map(item => {
            return <MemberCard data={item} key={item.id} />;
          })}
        </div>
      </div>
    </StyledTeamSection>
  );
};

const StyledTeamSection = styled.div`
  padding: 40px 16px 0;
  display: flex;
  justify-content: center;
  .team-members-wrapper {
    max-width: 1334px;
    flex: 1;
    position: relative;
    padding: 0 0 64px;
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.lightGray};
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 16px;
    }
  }
  .team-members {
    display: grid;
    grid-template-columns: repeat(4, minmax(300px, 320px));
    grid-gap: 32px 18px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 32px 16px 0;
    .team-members {
      grid-template-columns: repeat(2, minmax(360px, 1fr));
      grid-gap: 32px 16px;
    }
    .team-members-wrapper {
      padding: 0 0 36px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 32px 20px 0;
    .team-members-wrapper {
      &__title {
        text-align: center;
      }
    }
    .team-members {
      grid-template-columns: 1fr;
    }
  }
`;

export default TeamSection;
