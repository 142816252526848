import React from 'react';
import { Components } from 'sitedata';
import IndexLayout from 'layouts';
import TopSection from 'views/OurTeamPage/TopSection';
import TeamSection from 'views/OurTeamPage/TeamSection';

const OurTeamPage = () => (
  <IndexLayout>
    <Components.Header />
    <TopSection />
    <TeamSection />
    <Components.Footer />
  </IndexLayout>
);
export default OurTeamPage;
