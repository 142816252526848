import React from 'react';
import { styled } from 'styles';

const MemberCard = ({ data }) => {
  return (
    <StyledMemberCard className="member-card">
      <div className="member-card__photo-block">
        <img src={data.photo} alt="member-photo" className="member-card__photo" />
      </div>
      <p className="member-card__name">{data.name}</p>
      <p className="member-card__position">{data.position}</p>
      <a href={`mailto: ${data.email}`} className="member-card__email">
        {data.email}
      </a>
    </StyledMemberCard>
  );
};

const StyledMemberCard = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .member-card {
    &__photo-block {
      width: 100%;
      height: 320px;
      margin: 0 0 16px;
      border-radius: ${props => props.theme.misc.borderRadius};
      overflow: hidden;
    }
    &__photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__name {
      margin: 0 0 8px;
      font-size: 16px;
      line-height: 24px;
    }
    &__position {
      margin: 0 0 12px;
      font-size: 12px;
      line-height: 16px;
    }
    &__email {
      font-size: 12px;
      line-height: 14px;
      color: ${props => props.theme.colors.primary};
      text-decoration: none;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .member-card {
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    box-shadow: none;
    .member-card {
      &__photo-block {
      }
    }
  }
`;

export default MemberCard;
