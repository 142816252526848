import teamMember1 from 'assets/images/team-member-1.jpg';
import teamMember2 from 'assets/images/team-member-2.jpg';

const teamMembers = [
  {
    id: 1,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 2,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  },
  {
    id: 3,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 4,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  },
  {
    id: 5,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 6,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  },
  {
    id: 7,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 8,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  },
  {
    id: 9,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 10,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  },
  {
    id: 11,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 12,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  },
  {
    id: 13,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 14,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  },
  {
    id: 15,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 16,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  },
  {
    id: 17,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 18,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  },
  {
    id: 19,
    name: 'Soham Henry',
    position: 'CEO',
    email: 'melanie.russell@example.com',
    photo: teamMember1
  },
  {
    id: 20,
    name: 'Cameron Williamson',
    position: 'Broker',
    email: 'lillie.foster@example.com',
    photo: teamMember2
  }
];
export default teamMembers;
